// components/InputRange/InputRange.jsx

import "./InputRange.styles.scss";

import React, { useState } from "react";
import ReactInputRange from "react-input-range";

import { randomString } from "../../helper/string.helpers";
import InputWrapper from "../InputWrapper/InputWrapper";
import InputNumber from "../InputNumber/InputNumber";
/**
 * @typedef {Object} InputRangeProps
 * @property {string | null} [id] - The ID for the input
 * @property {string} [label] - The label to appear above the input
 * @property {boolean} [srOnlyLabel] -
 * @property {string} [moreInfo] -
 * @property {string} [footnote] -
 * @property {string} [theme] -
 * @property {boolean} [required] -
 * @property {boolean} [invalid] -
 * @property {string} [invalidMessage] -
 * @property {boolean} [disabled] -
 * @property {number} [minValue] -
 * @property {number} [maxValue] -
 * @property {number} [step] -
 * @property {Range | number} value -
 * @property {React.Dispatch<React.SetStateAction<InputTextProps["value"]>>} onValueChange -
 */

/** @type InputRangeProps */
const defaultProps = {
	invalidMessage: "This is required",
	theme: "default",
	labelDescriptor: "",
};

/**
 * A Range Component
 * @param {InputRangeProps} props
 * @return {JSX.Element}
 * @constructor
 */
const InputRange = (props) => {
	props = { ...defaultProps, ...props };

	const [id] = useState(props.id || randomString());

	const handleChange = (e) => {
		console.log("value from range handleChange", e.target.value);
		// Get value from the target element as a string first
		let strValue = e.target.value;

		if (strValue !== "") {
			// Parse the value to an integer (this effectively removes any leading zeros)
			let value = parseInt(strValue, 10);

			// Ensure value is within the range
			if (value < props.minValue) {
				value = props.minValue;
			} else if (value > props.maxValue) {
				value = props.maxValue;
			}

			// Pass value to the onValueChange function
			props.onValueChange && props.onValueChange(Number(value));
		} else {
			// If the input is blank, we set the value to the minimum value.
			props.onValueChange && props.onValueChange(props.minValue);
		}
	};

	const handleRangeChange = (value) => {
		let parsedValue = parseInt(value, 10);

		// Ensure value is within the range
		if (parsedValue < props.minValue) {
			parsedValue = props.minValue;
		} else if (parsedValue > props.maxValue) {
			parsedValue = props.maxValue;
		}

		// Pass value to the onValueChange function
		props.onValueChange && props.onValueChange(parsedValue);
	};

	return (
		<InputWrapper
			id={id}
			label={props.label}
			srOnlyLabel={props.srOnlyLabel}
			required={props.required}
			moreInfo={props.moreInfo}
			footnote={props.footnote}
			invalid={props.invalid}
			invalidMessage={props.invalidMessage}
		>
			{props.includeNestedInput && (
				<div className='nested-input'>
					<input
						type={"text"}
						step={props.step}
						min={props.minValue}
						max={props.maxValue}
						inputMode='numeric'
						value={parseInt(props.value, 10)}
						onChange={handleChange}
					/>
					<span className='percentage-sign'>{props.labelDescriptor}</span>
				</div>
			)}
			<div className={`input-range-wrapper ${props.includeNestedInput && "remove-slider-title"}`}>
				<ReactInputRange
					minValue={props.minValue}
					maxValue={props.maxValue}
					step={props.step}
					formatLabel={(value) =>
						`${props.formatLabel(value)}${props.labelDescriptor && props.labelDescriptor}`
					}
					value={props.value}
					onChange={handleRangeChange}
				/>
			</div>
		</InputWrapper>
	);
};

export default InputRange;
