import React, { useRef, useEffect, useState, useCallback, useMemo } from "react";
import MapboxMap from "../../components/Map/Map";
import CustomMarker from "../../components/Map/CustomMarker";
import "./ComparisonMarker.scss";
import "./ComparisonViewMap.scss";

function ComparisonViewMap({ visibleLocations = [], isGenerating, footnoteContent }) {
	const [locationChunks, setLocationChunks] = useState([]);
	const listRef = useRef(null);
	const itemRef = useRef(null);

	const validLocations = useMemo(
		() =>
			visibleLocations.filter(
				(location) =>
					location &&
					typeof location.latitude === "number" &&
					typeof location.longitude === "number" &&
					!isNaN(location.latitude) &&
					!isNaN(location.longitude),
			),
		[visibleLocations],
	);

	const calculateChunks = useCallback(() => {
		if (!validLocations.length) return;

		const itemsPerColumn = 5;
		const columnsPerPage = 4;
		const itemsPerPage = itemsPerColumn * columnsPerPage;

		const totalPages = Math.ceil(validLocations.length / itemsPerPage);

		const pages = [];

		for (let pageNum = 0; pageNum < totalPages; pageNum++) {
			const pageStartIndex = pageNum * itemsPerPage;
			const pageEndIndex = Math.min((pageNum + 1) * itemsPerPage, validLocations.length);
			const locationsForPage = validLocations.slice(pageStartIndex, pageEndIndex);

			const columns = [];
			for (let colNum = 0; colNum < columnsPerPage; colNum++) {
				const colStartIndex = colNum * itemsPerColumn;
				const colEndIndex = Math.min(colStartIndex + itemsPerColumn, locationsForPage.length);

				if (colStartIndex < locationsForPage.length) {
					columns.push(locationsForPage.slice(colStartIndex, colEndIndex));
				}
			}

			if (columns.length > 0) {
				pages.push(columns);
			}
		}

		setLocationChunks(pages);
	}, [validLocations]);

	useEffect(() => {
		if (!validLocations.length) return;
		calculateChunks();
		window.addEventListener("resize", calculateChunks);
		return () => window.removeEventListener("resize", calculateChunks);
	}, [calculateChunks, validLocations]);

	const renderLocationColumns = useCallback(
		(columns) => (
			<div className='location-list'>
				{columns.map((columnLocations, columnIndex) => (
					<ol key={columnIndex} className='location-column'>
						{columnLocations.map((location, index) => (
							<li
								key={location._id || index}
								ref={columnIndex === 0 && index === 0 ? itemRef : null}
								className='location-item'
							>
								<span>
									{location?.location_name || "Unnamed Location"} {location?.is_current ? "*" : ""}
								</span>
							</li>
						))}
					</ol>
				))}
			</div>
		),
		[],
	);

	const renderMap = useCallback((locations) => {
		if (!locations?.length) return null;

		const initialViewState = {
			latitude: locations[0].latitude,
			longitude: locations[0].longitude,
			zoom: 9,
		};
		//
		return (
			<div className='map-container'>
				<MapboxMap
					initialViewState={initialViewState}
					scrollZoom={false}
					doubleClickZoom={false}
					onMapLoad={(fitBounds) => {
						if (locations.length > 1) {
							const coordinates = locations
								.filter((loc) => loc.longitude && loc.latitude)
								.map((loc) => [loc.longitude, loc.latitude]);

							if (coordinates.length > 0) {
								fitBounds(coordinates, {
									top: 50,
									bottom: 50,
									left: 50,
									right: 50,
								});
							}
						}
					}}
				>
					{locations.map((location, index) => (
						<CustomMarker
							className={`comparison-marker text-white ${location.is_current && "blue"}`}
							index={index}
							key={location._id || index}
							lat={location.latitude}
							lng={location.longitude}
							name={location.is_current ? `*` : ""}
							image={""}
							popupContent={""}
						/>
					))}
				</MapboxMap>
			</div>
		);
	}, []);

	const renderFootnote = useCallback(() => {
		if (!footnoteContent) return null;

		return (
			<div className='map-footnote'>
				<div dangerouslySetInnerHTML={{ __html: footnoteContent }} />
			</div>
		);
	}, [footnoteContent]);

	const renderLocationPage = useCallback(
		(pageColumns, pageIndex) => {
			if (!pageColumns?.length) return null;

			const allLocations = pageColumns.flat();
			const hasFootnote = !!footnoteContent;

			return (
				<div key={pageIndex} className='print-map-page'>
					<div className='locations-wrapper'>
						<div className={`map ${hasFootnote ? "mb-0" : ""}`}>{renderMap(allLocations)}</div>

						{renderFootnote()}
						<div className='locations' ref={listRef}>
							{renderLocationColumns(pageColumns)}
						</div>
					</div>
				</div>
			);
		},
		[renderMap, renderLocationColumns, renderFootnote],
	);

	if (!validLocations.length) {
		return null;
	}

	return (
		<>
			<div className='web-view locations-map'>
				{locationChunks[0] && renderLocationPage(locationChunks[0], "main")}
			</div>

			<div className={`map-print print-preview-wrapper ${isGenerating ? "d-block" : "d-none"}`}>
				{locationChunks.map((pageColumns, index) =>
					pageColumns?.length ? renderLocationPage(pageColumns, index) : null,
				)}
			</div>
		</>
	);
}

export default ComparisonViewMap;
