import React from "react";
import "./ShimmerLogo.scss";
function ShimmerLogo() {
	return (
		<div className={`site-loader-main  content-loader shimmer`}>
			<div className='site-loader-inner '>
				<div>
					<div className='loading-circle one' />
				</div>
				<div>
					<div className='loading-circle two' />
					<div className='loading-circle three' />
				</div>
				<div>
					<div className='loading-circle four' />
					<div className='loading-circle five' />
					<div className='loading-circle six' />
				</div>
			</div>
		</div>
	);
}

export default ShimmerLogo;
