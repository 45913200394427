import { debounce } from "lodash";

import { convertToPercent } from "../../helper/number.helpers";
import {
	createDashboard,
	editDashboardDetails,
	getSOCCode,
	validateCSVFile,
} from "../../services/dashboards/townson-dashboard.services/townson-dashboard-data";

export const handleSocCodeUpdate = (newSelectedSocCode, setSelectedSocCode) => {
	setSelectedSocCode(newSelectedSocCode);
};
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

import { getBackendGeocode } from "../../services/dashboards/townson-dashboard.services/geocode.service";

export const handleOnCurrentLocationDescriptionChange = (
	name,
	value,
	currentLocationDescription,
	setCurrentLocationDescription,
	checkUrlsForMaliciousContent,
) => {
	if (name === "notes") {
		const regex = new RegExp(
			"https?:\\/\\/[^\\s/$.?#].[^\\s]*|ftps?:\\/\\/[^\\s/$.?#].[^\\s]*|(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
			"gi",
		);

		const urls = value.match(regex) || [];
		if (urls.length > 0) {
			const debouncedCheckUrlsForMaliciousContent = debounce(checkUrlsForMaliciousContent, 2000);
			debouncedCheckUrlsForMaliciousContent(urls);
		}
	}

	setCurrentLocationDescription({
		...currentLocationDescription,
		[name]: value,
	});
};

export const uploadCSV = async (file, setProgressFileUpload, setCsvFile, setCsvFileName, setErrorFileUpload) => {
	if (!file) return;
	const formData = new FormData();
	formData.append("employee_csv", file);
	const res = await validateCSVFile(formData, setProgressFileUpload);
	if (res?.status === "success") {
		setCsvFile(res?.data?.employee_csv_path);
	} else {
		setCsvFileName();
		setCsvFile();
		setErrorFileUpload(res.message);
	}
};

export const handleOnChangeFile = (event, setCsvFile, setCsvFileName, setErrorFileUpload, uploadCSV, removeCSV) => {
	if (event && event.target && event.target.files[0]) {
		setErrorFileUpload();
		setCsvFileName(event.target.files[0]?.name);
		uploadCSV(event.target.files[0]);
	} else if (event && event.name) {
		setErrorFileUpload();
		setCsvFileName(event.name);
		uploadCSV(event);
	} else {
		removeCSV();
		setCsvFile();
		setCsvFileName();
	}
};

const allowedExtensions = ["csv", "xlsx", "xls", "xlsm", "xlsb"];

export const handleOnChangeLocationFile = (
	event,
	setApprovingCsvUpload,
	setProceedWithCsvUpload,
	setCsvError,
	setTempData,
	setCSVProcessingLoading,
	setCsvApproval,
	setTempResults,
	setNumberOfRows,
	maxAllowed,
	officeLocationType = false,
) => {
	const validateFileExtension = (fileName) => {
		const extension = fileName.split(".").pop().toLowerCase();
		return allowedExtensions.includes(extension);
	};

	const validateAddress = (address, city, state, zip, rowIndex) => {
		const errors = [];

		if (!address?.trim()) errors.push("Property Address is required");
		if (!city?.trim()) errors.push("City is required");
		if (!state?.trim()) errors.push("State is required");
		if (!zip?.trim()) errors.push("ZIP code is required");

		if (address?.trim() === "123 StreetName") errors.push("Please replace default Property Address");
		if (city?.trim() === "City") errors.push("Please replace default City");
		if (state?.trim() === "State") errors.push("Please replace default State");
		if (zip?.trim() === "12345") errors.push("Please replace default ZIP code");

		if (errors.length > 0) {
			throw new Error(`Row ${rowIndex}: ${errors.join(". ")}`);
		}
	};

	const processRow = (row, headers, locationType, rowIndex) => {
		const getValue = (fieldName) => {
			const index = headers.findIndex((header) => header.trim() === fieldName.trim());
			const value = index !== -1 ? row[index] : "";
			return (
				value
					?.toString()
					.replace(/[\r\n]+/g, " ")
					.replace(/\s+/g, " ")
					.trim() || ""
			);
		};

		const propertyAddress = getValue("Property Address");
		const city = getValue("City");
		const state = getValue("State");
		const zip = getValue("Zip");

		validateAddress(propertyAddress, city, state, zip, rowIndex);

		const baseObj = {
			Location: `${propertyAddress}${propertyAddress && ", "}${city}${city && ", "}${state} ${zip}`,
			status: getValue("Building Status"),
			opex_value: getValue("OPEX"),
			leasing_company: getValue("Leasing Company"),
			notes: getValue("Notes"),
			is_current: getValue("Current Location (Yes/No)").toLowerCase() === "yes",
			building_sf: getValue("Building SF"),
			available_sf: getValue("Available SF"),
			base_rent: getValue("Base Rent"),
			gross_price_sf: getValue("Gross PSF"),
		};

		const typeSpecificObj = locationType
			? {
					// Industrial fields
					office_sf: getValue("Office SF"),
					clear_height: getValue("Clear Height"),
					car_trailer_parking: getValue("Car/Trailer Parking"),
					docs_grade_level_doors: getValue("Docs/Grade Level Doors"),
			  }
			: {
					// Office fields
					year_built: getValue("Year Built"),
					parking_ratio: getValue("Parking Ratio"),
					location_suite: getValue("Location Suite"),
					percent_leased: convertToPercent(getValue("Percent Leased")),
					electric_price_sf: getValue("Electric Price SF"),
			  };

		return {
			...baseObj,
			...typeSpecificObj,
		};
	};
	const handleFile = async (file) => {
		if (!validateFileExtension(file.name)) {
			throw new Error(`Invalid file type. Allowed extensions are: ${allowedExtensions.join(", ")}`);
		}

		const reader = new FileReader();

		reader.onload = async (evt) => {
			try {
				const wb = XLSX.read(evt.target.result, { type: "binary" });

				if (!wb?.SheetNames?.length) {
					throw new Error("Please select valid proposed location file");
				}

				const ws = wb.Sheets[wb.SheetNames[0]];
				const data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "", blankrows: false });

				if (!data?.length) {
					throw new Error("Please select valid proposed location file");
				}

				const headers = data[0];

				const headerSet = new Set(headers.map((h) => h.trim()));

				const industrialOnlyFields = new Set([
					"Office SF",
					"Clear Height",
					"Car/Trailer Parking",
					"Docks/Grade Level Doors",
				]);

				const officeOnlyFields = new Set([
					"Year Built",
					"Parking Ratio",
					"Suite",
					"Percent Leased",
					"Electric Price SF",
				]);

				const hasIndustrialFields = Array.from(industrialOnlyFields).some((field) => headerSet.has(field));
				const hasOfficeFields = Array.from(officeOnlyFields).some((field) => headerSet.has(field));

				let detectedType;
				if (hasIndustrialFields && !hasOfficeFields) {
					detectedType = "industrial";
				} else if (hasOfficeFields && !hasIndustrialFields) {
					detectedType = "office";
				} else if (!hasIndustrialFields && !hasOfficeFields) {
					throw new Error("Invalid template: Missing required fields for both office and industrial types");
				} else {
					throw new Error(
						"Invalid template: Contains mixed fields from both office and industrial templates",
					);
				}

				if (
					(detectedType === "industrial" && !officeLocationType) ||
					(detectedType === "office" && officeLocationType)
				) {
					throw new Error(
						`Template mismatch: ${
							officeLocationType ? "industrial" : "office"
						} location type selected but uploaded ${detectedType} template. Please use the correct template for the selected location type.`,
					);
				}

				setTempData(data);

				const totalRows = data.length - 1;
				const maxRows = Math.min(totalRows + 1, maxAllowed + 1);
				setNumberOfRows(totalRows);

				const results = [];
				for (let i = 1; i < maxRows; i++) {
					const row = data[i];

					try {
						const processedRow = processRow(row, headers, officeLocationType, i);
						results.push(processedRow);
					} catch (error) {
						throw new Error(`Error processing row ${i}: ${error.message}`);
					}
				}

				if (results.length > 0) {
					setCSVProcessingLoading(true);
					setCsvApproval(results);
					setApprovingCsvUpload(true);
					setTempResults(results);
				}
			} catch (error) {
				setCsvError([error.message]);
				setApprovingCsvUpload(false);
				setProceedWithCsvUpload(false);
			}
		};

		reader.readAsBinaryString(file);
	};

	if (event?.target?.files?.[0]) {
		setApprovingCsvUpload(false);
		setProceedWithCsvUpload(false);
		setCsvError([]);

		handleFile(event.target.files[0]).catch((error) => {
			setCsvError([error.message]);
		});
	}
};

export const uploadCustomers = async (
	file,
	setCustomersProgressFileUpload,
	setCustomersFile,
	setCustomersFileName,
	setCustomersErrorFileUpload,
) => {
	if (!file) return;
	const formData = new FormData();
	formData.append("customer_csv", file);
	const res = await validateCSVFile(formData, setCustomersProgressFileUpload);
	if (res?.status === "success") {
		setCustomersFile(res?.data?.customer_csv_path);
	} else {
		setCustomersFileName();
		setCustomersFile();
		setCustomersErrorFileUpload(res.message);
	}
};

export const handleCustomersOnChangeFile = (
	event,
	setCustomersErrorFileUpload,
	setCustomersFileName,
	setCustomersFile,
	uploadCustomers,
	removeCustomers,
) => {
	if (event && event.target && event.target.files[0]) {
		setCustomersErrorFileUpload();
		setCustomersFileName(event.target.files[0]?.name);
		uploadCustomers(event.target.files[0]);
	} else if (event && event.name) {
		setCustomersErrorFileUpload();
		setCustomersFileName(event.name);
		uploadCustomers(event);
	} else {
		removeCustomers();
		setCustomersFile();
		setCustomersFileName();
	}
};

export const resumeProcessing = async (
	setCSVProcessingLoading,
	setCsvError,
	setLocation,
	setProposedLocationIndex,
	setLocationDescription,
	tempResults,
	maxAllowed,
	tempData,
	openBiModal,
) => {
	const tempLocation = [];
	const tempDescription = [];
	const input = [];

	for (let i = 0; i < tempResults?.length; i++) {
		if (i < maxAllowed && tempResults[i]?.Location) {
			input.push({
				address: `${tempResults[i]?.Location.replace("#", "")}.json?limit=${maxAllowed}&countrySet=US`,
			});
		}
	}

	if (!openBiModal || tempResults.length === 0) {
		return;
	}

	setCSVProcessingLoading(true);

	const apiData = await getBackendGeocode({
		locations: input,
	});

	const error = ["ERROR:"];

	// Process results using position-based mapping
	// Score/Confidence is all over the place so we're just marrying up results
	for (let i = 0; i < tempResults?.length; i++) {
		if (i < maxAllowed && tempData[i + 1][3]) {
			const apiResponse = apiData?.batchItems[i]?.response?.results[0];

			if (apiResponse?.position) {
				tempDescription.push({
					notes: tempResults[i]["notes"] ? tempResults[i]["notes"] : "",
					opex_value: parseFloat(tempResults[i]["opex_value"]) > 0.0 ? tempResults[i]["opex_value"] : "",
					year_built: parseFloat(tempResults[i]["year_built"]) > 0.0 ? tempResults[i]["year_built"] : "",
					building_sf: parseFloat(tempResults[i]["building_sf"]) > 0.0 ? tempResults[i]["building_sf"] : "",
					parking_ratio: tempResults[i]["parking_ratio"] ? tempResults[i]["parking_ratio"] : "",
					available_sf:
						parseFloat(tempResults[i]["available_sf"]) > 0.0 ? tempResults[i]["available_sf"] : "",
					office_sf: parseFloat(tempResults[i]["office_sf"]) > 0.0 ? tempResults[i]["office_sf"] : "",
					clear_height:
						parseFloat(tempResults[i]["clear_height"]) > 0.0 ? tempResults[i]["clear_height"] : "",
					car_trailer_parking:
						parseFloat(tempResults[i]["car_trailer_parking"]) > 0.0
							? tempResults[i]["car_trailer_parking"]
							: "",
					docs_grade_level_doors:
						parseFloat(tempResults[i]["docs_grade_level_doors"]) > 0.0
							? tempResults[i]["docs_grade_level_doors"]
							: "",
					base_rent: parseFloat(tempResults[i]["base_rent"]) > 0.0 ? tempResults[i]["base_rent"] : "",
					location_suite: tempResults[i]["location_suite"] ? tempResults[i]["location_suite"] : "",
					percent_leased: tempResults[i]["percent_leased"] ? tempResults[i]["percent_leased"] : "",
					electric_price_sf:
						parseFloat(tempResults[i]["electric_price_sf"]) > 0.0
							? tempResults[i]["electric_price_sf"]
							: "",
					gross_price_sf:
						parseFloat(tempResults[i]["gross_price_sf"]) > 0.0 ? tempResults[i]["gross_price_sf"] : "",
					leasing_company: tempResults[i]["leasing_company"] ? tempResults[i]["leasing_company"] : "",
					status:
						tempResults[i]["status"] === "Existing" ||
						tempResults[i]["status"] === "Under Construction" ||
						tempResults[i]["status"] === "Proposed"
							? tempResults[i]["status"]
							: "",
					is_current: tempResults[i]["is_current"] === true ? true : false,
				});
				tempLocation.push(apiResponse);
			} else {
				error.push(
					`${i + 1} - ${tempData[i + 1][0]}, ${tempData[i + 1][1]}, ${tempData[i + 1][2]}, ${
						tempData[i + 1][3]
					} not available`,
				);
			}
		}
	}

	if (error?.length > 1) {
		setCsvError(error);
	}

	if (tempLocation?.length > 0) {
		setLocation((prevLocation) => ({
			...prevLocation,
			proposedLocations: tempLocation,
		}));
		setProposedLocationIndex(tempDescription?.length);
		setLocationDescription(tempDescription);
	}

	setCSVProcessingLoading(false);
};

export const handleToggleCheckboxSocCode = (code, selectedSocCode, setSelectedSocCode) => {
	const exists = selectedSocCode.find((item) => item.id === code.id);

	if (exists) {
		setSelectedSocCode(selectedSocCode.filter((item) => item.id !== code.id));
	} else {
		setSelectedSocCode([...selectedSocCode, code]);
	}
};

export const logFormData = (formData) => {
	console.log("Form Data Contents:");
	for (let [key, value] of formData.entries()) {
		if (value instanceof File) {
			console.log(`${key}: File - ${value.name}`);
		} else if (typeof value === "object") {
			console.log(`${key}: ${JSON.stringify(value)}`);
		} else {
			console.log(`${key}: ${value}`);
		}
	}
};

export const getSocCodeList = async (setSocCodeList) => {
	const res = await getSOCCode();
	if (res?.code === 200) {
		setSocCodeList(res.data);
	}
};

export const createDashBoard = async (
	dashboardTitle,
	location,
	useOnetSearch,
	currentLocationDescription,
	csvFile,
	customersFile,
	locationPDFFilesNames,
	locationDescription,
	selectedSocCode,
	setLoading,
	setTrigger,
	setStep,
	setOpenBiModal,
	currentDashboardData,
	officeLocationType,
) => {
	setLoading(true);
	setTrigger(false);
	const bodyFormData = new FormData();
	const currentDashboardId = currentDashboardData?.id ?? null;

	currentDashboardId && bodyFormData.append("currentDashboardId", currentDashboardId);
	bodyFormData.append(
		"dashboard_title",
		dashboardTitle !== "" ? dashboardTitle : location?.currentLocation?.address?.freeformAddress,
	);
	bodyFormData.append("is_industrial_location_type", officeLocationType ? true : false);
	bodyFormData.append("use_search", useOnetSearch ? true : false);
	bodyFormData.append("created_from", currentDashboardData?._id ? "score_insights" : "");
	bodyFormData.append("created_from_id", currentDashboardData?._id ?? "none");

	if (location?.currentLocation?.address?.freeformAddress) {
		bodyFormData.append(
			"current_location",
			location?.currentLocation?.address?.freeformAddress ? location.currentLocation.address.freeformAddress : "",
		);
		bodyFormData.append("latitude", location.currentLocation.position.lat || "");
		bodyFormData.append("longitude", location.currentLocation.position.lon || "");
		bodyFormData.append("opex_value_current", currentLocationDescription.opex_value_current || "");
		bodyFormData.append("is_current_current", currentLocationDescription.is_current || false);
		bodyFormData.append(
			"status_current",
			currentLocationDescription ? currentLocationDescription.status || "" : "",
		);
		bodyFormData.append(
			"leasing_company_current",
			currentLocationDescription ? currentLocationDescription.leasing_company || "" : "",
		);
	}

	if (csvFile) {
		bodyFormData.append("employee_csv_path", csvFile);
	}
	if (customersFile) {
		bodyFormData.append("customer_csv_path", customersFile);
	}

	if (locationPDFFilesNames) {
		locationPDFFilesNames.forEach((locationData) => {
			if (locationData.files && locationData.files.length > 0) {
				const pdfsForLocation = locationData.files.map((file) => ({
					name: file.name || file,
					url: file.url || file,
				}));
				bodyFormData.append(`pdfs[${locationData.location_index}]`, JSON.stringify(pdfsForLocation));
			}
		});
	}

	location.proposedLocations.forEach((item, index) => {
		if (item?.address) {
			bodyFormData.append("proposed_location[]", item.address.freeformAddress || "");
			const zipCode = item.address?.postalCode ? item.address.postalCode.split(",")[0].trim() : "";

			bodyFormData.append("zip_code[]", zipCode);
			bodyFormData.append("proposed_location_lat[]", item.position.lat || "");
			bodyFormData.append("proposed_location_long[]", item.position.lon || "");

			bodyFormData.append(
				"opex_value[]",
				locationDescription[index] ? locationDescription[index].opex_value || "" : "",
			);
			bodyFormData.append(
				"year_built[]",
				locationDescription[index] ? locationDescription[index].year_built || "" : "",
			);
			bodyFormData.append(
				"building_sf[]",
				locationDescription[index] ? locationDescription[index].building_sf || "" : "",
			);
			bodyFormData.append(
				"parking_ratio[]",
				locationDescription[index] ? locationDescription[index].parking_ratio || "" : "",
			);
			bodyFormData.append(
				"available_sf[]",
				locationDescription[index] ? locationDescription[index].available_sf || "" : "",
			);
			bodyFormData.append(
				"office_sf[]",
				locationDescription[index] ? locationDescription[index].office_sf || "" : "",
			);
			bodyFormData.append(
				"clear_height[]",
				locationDescription[index] ? locationDescription[index].clear_height || "" : "",
			);
			bodyFormData.append(
				"car_trailer_parking[]",
				locationDescription[index] ? locationDescription[index].car_trailer_parking || "" : "",
			);
			bodyFormData.append(
				"docs_grade_level_doors[]",
				locationDescription[index] ? locationDescription[index].docs_grade_level_doors || "" : "",
			);
			bodyFormData.append(
				"location_suite[]",
				locationDescription[index] ? locationDescription[index].location_suite || "" : "",
			);
			bodyFormData.append(
				"percent_leased[]",
				locationDescription[index] ? locationDescription[index].percent_leased || "" : "",
			);
			bodyFormData.append(
				"base_rent[]",
				locationDescription[index] ? locationDescription[index].base_rent || "" : "",
			);
			bodyFormData.append(
				"electric_price_sf[]",
				locationDescription[index] ? locationDescription[index].electric_price_sf || "" : "",
			);
			bodyFormData.append(
				"gross_price_sf[]",
				locationDescription[index] ? locationDescription[index].gross_price_sf || "" : "",
			);
			bodyFormData.append(
				"is_current[]",
				locationDescription[index] ? locationDescription[index].is_current || false : false,
			);
			bodyFormData.append("status[]", locationDescription[index] ? locationDescription[index].status || "" : "");
			bodyFormData.append(
				"leasing_company[]",
				locationDescription[index] ? locationDescription[index].leasing_company || "" : "",
			);
			bodyFormData.append("notes[]", locationDescription[index] ? locationDescription[index].notes || "" : "");
		}
	});

	selectedSocCode.forEach((item) => {
		const socCodeData = useOnetSearch ? JSON.stringify(item) : item.id;
		bodyFormData.append("soc_code[]", socCodeData || "");
	});
	// logFormData(bodyFormData);

	if (currentDashboardId) {
		const res = await editDashboardDetails(bodyFormData);
		if (res?.result === "ok") {
			toast.success("Succesfully edited Business Insights dashboard");
			setOpenBiModal(false);
			setStep(1);
		}
	} else {
		const res = await createDashboard(bodyFormData);
		if (res?.code === 200) {
			toast.success("Succesfully created Business Insights dashboard");
			setOpenBiModal(false);
			setStep(1);
		}
	}
};

export const validateStep = (validationError, setValidationError, step, dashboardTitle, location, selectedSocCode) => {
	let isValid = true;
	const newValidationError = { ...validationError };

	if (step === 1) {
		if (!dashboardTitle.trim()) {
			newValidationError.title.error = true;
			isValid = false;
		} else {
			newValidationError.title.error = false;
		}

		if (location.proposedLocations.length === 0 || location.proposedLocations.every((loc) => loc === null)) {
			newValidationError.location.error = true;
			isValid = false;
		} else {
			newValidationError.location.error = false;
		}
	}

	if (step === 4) {
		if (selectedSocCode.length === 0) {
			newValidationError.socCode.error = true;
			isValid = false;
		} else {
			newValidationError.socCode.error = false;
		}
	}

	setValidationError(newValidationError);
	return isValid;
};

export const checkUrlsForMaliciousContent = async (urls) => {
	let threatUrls = [];
	urls.map((url) => {
		threatUrls.push({ url: url });
	});

	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	var raw = JSON.stringify({
		"client": {
			"clientId": "bluechip-insights",
			"clientVersion": "1.5.2",
		},
		"threatInfo": {
			"threatTypes": ["MALWARE", "SOCIAL_ENGINEERING", "POTENTIALLY_HARMFUL_APPLICATION", "UNWANTED_SOFTWARE"],
			"platformTypes": ["ANY_PLATFORM"],
			"threatEntryTypes": ["URL"],
			"threatEntries": threatUrls,
		},
	});

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};

	await fetch(
		"https://safebrowsing.googleapis.com/v4/threatMatches:find?key=" +
			import.meta.env.VITE_REACT_GOOGLE_SAFE_BROWSING_API_KEY,
		requestOptions,
	)
		.then((response) => response.text())
		.then((result) => {
			// If the result is not empty, then we have a malicious URL.
			if (!isEmptyObject(JSON.parse(result))) {
				// We need to show a warning to the user.
				setErrorLocationValidation((prevArray) => [
					...prevArray,
					{ type: "currentLocation", message: "* Please remove the malicious URL from the notes" },
				]);
			}
		})
		.catch((error) => console.warn("error", error));
};

export const handleLocationPDFOnChangeFile = (
	locationFiles,
	setLocationPDFFilesErrorUpload,
	setLocationPDFFilesNames,
	uploadLocationPDFS,
	removeLocationPDFS,
	setLocationPDFFiles,
) => {
	if (locationFiles.type === "existing") {
		setLocationPDFFilesNames(locationFiles.pdfs);
		return;
	}
	if (locationFiles && locationFiles.files && locationFiles.files?.length > 0) {
		setLocationPDFFilesErrorUpload();
		uploadLocationPDFS(locationFiles.location_index, locationFiles.files);
	} else if (locationFiles.e && locationFiles.e.target && locationFiles.e.target.files) {
		setLocationPDFFilesErrorUpload();
		uploadLocationPDFS(locationFiles.location_index, locationFiles.e.target.files);
	} else if (locationFiles.e && locationFiles.e.name) {
		setLocationPDFFilesErrorUpload();
		uploadLocationPDFS(locationFiles.e);
	} else {
		removeLocationPDFS(locationPDFFiles);
		setLocationPDFFiles();
		setLocationPDFFilesNames();
	}
};

export const uploadLocationPDFS = async (
	location_index,
	files,
	setlocationPDFFilesUpload,
	setLocationPDFFilesErrorUpload,
	setLocationPDFFilesNames,
	setLocationPDFFiles,
	validatePDFFiles,
) => {
	if (!files) return;

	let uploadedFiles = [];
	let uploadProgress = [];

	for (const [index, file] of Array.from(files).entries()) {
		const formData = new FormData();
		formData.append(`files[${location_index}][${index}]`, file);

		const config = {
			onUploadProgress: (progressEvent) => {
				const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				uploadProgress[index] = progress;
				setlocationPDFFilesUpload({ ...uploadProgress });
			},
		};

		const res = await validatePDFFiles(formData, config.onUploadProgress);

		if (res?.status === "success") {
			const uploadedFile = res?.data[0];
			uploadedFiles.push(uploadedFile);
			uploadProgress[index] = 100; // Set to 100% when upload is complete
			setlocationPDFFilesUpload({ ...uploadProgress });
		} else {
			setLocationPDFFilesErrorUpload(res.message);
			break;
		}
	}

	if (uploadedFiles.length > 0) {
		setLocationPDFFilesNames((prevFiles) => {
			const existingLocation = prevFiles.find((loc) => loc.location_index === location_index);

			if (existingLocation) {
				return prevFiles.map((loc) =>
					loc.location_index === location_index ? { ...loc, files: [...loc.files, ...uploadedFiles] } : loc,
				);
			} else {
				return [...prevFiles, { location_index, files: uploadedFiles }];
			}
		});

		setTimeout(() => {
			setLocationPDFFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
		}, 0);
	}
};

export const removeUploadedFile = (fileToRemove, locationPDFFilesNames, setLocationPDFFilesNames) => {
	const updatedArray = locationPDFFilesNames
		.map((locationFile) => {
			const updatedFiles = locationFile.files.filter((file) => file !== fileToRemove);
			return { ...locationFile, files: updatedFiles };
		})
		.filter((locationFile) => locationFile.files.length > 0);

	setLocationPDFFilesNames(updatedArray);
};

export const removeLocationPDFS = async (file, removeLocationPDFUploadedFile, dispatch, getDashboard, dashboardId) => {
	if (!file) return;

	const formData = new FormData();
	formData.append("pdf", JSON.stringify(file));

	const res = await removeLocationPDFUploadedFile(formData);
	if (res?.code === 200) {
		dispatch(getDashboard(dashboardId));
	}
};

export const getInitialLocationState = () => ({
	currentLocation: {},
	proposedLocations: [{}],
});

export const getInitialValidationErrorState = () => ({
	title: { error: null, message: "Please add a title to the dashboard" },
	location: { error: null, message: "At least one location is required" },
	socCode: { error: null, message: "Please select at least one SOC code" },
});

export const getInitialStepOneData = () => ({
	location: {
		currentLocation: {},
		proposedLocations: [{}],
	},
	locationDescription: [],
	proposedLocationIndex: 1,
});

const STEP_TITLES = {
	1: "Locations",
	2: "Employees",
	3: "Customers",
	4: "Labor Profiles (SOC)",
};

/**
 * Generates a formatted title string for a dashboard step.
 *
 * @param {number} step - The current step number
 * @param {string} customTitle - Prefix for the dashboard
 * @returns {string|undefined} A formatted string combining the custom title and step information
 */
export const getStepTitle = (step, customTitle) => {
	const baseTitle = customTitle;
	const stepTitle = STEP_TITLES[step];
	if (customTitle) {
		return `${baseTitle} ${stepTitle} (step ${step} of 4)`;
	}
};

export const getModalCustomButtons = ({
	step,
	csvApproval,
	approvingCsvUpload,
	proceedWithCsvUpload,
	setProceedWithCsvUpload,
	setApprovingCsvUpload,
	setCSVProcessingLoading,
	validationError,
	setStep,
	handleNextStep,
	currentDashboardData,
}) => {
	const hasValidationError = (step) => {
		switch (step) {
			case 1:
				return validationError.title.error || validationError.location.error;
			case 4:
				return validationError.socCode.error;
			default:
				return false;
		}
	};

	return [
		<div key='save-div' className='justify-end'>
			{csvApproval !== null && approvingCsvUpload === true && proceedWithCsvUpload === false ? (
				<>
					<button
						style={{ marginRight: "24px" }}
						className='btn primary'
						onClick={() => {
							setProceedWithCsvUpload(true);
							setApprovingCsvUpload(false);
						}}
					>
						Approve
					</button>
					<button
						className='btn secondary outline'
						onClick={() => {
							setApprovingCsvUpload(false);
							setProceedWithCsvUpload(false);
							setCSVProcessingLoading(false);
						}}
					>
						Cancel
					</button>
				</>
			) : (
				<>
					{step === 1 && (
						<span className='validation-errors' style={{ marginRight: "auto" }}>
							{validationError.title.error && (
								<span className='text-danger' style={{ color: "red", marginRight: "15px" }}>
									{validationError.title.message}
								</span>
							)}
							{validationError.location.error && (
								<span className='text-danger' style={{ color: "red" }}>
									{validationError.location.message}
								</span>
							)}
						</span>
					)}

					{step !== 1 && (
						<button
							onClick={() => {
								setStep((prevStep) => Math.max(1, prevStep - 1));
							}}
							style={{ marginRight: "24px" }}
							className='btn secondary outline'
						>
							Back
						</button>
					)}
					{step !== 4 && (
						<button
							onClick={handleNextStep}
							style={{ marginRight: "24px" }}
							className={`btn secondary outline ${hasValidationError(step) ? "error" : ""}`}
						>
							Next
						</button>
					)}
					{step === 4 && (
						<button
							onClick={handleNextStep}
							className={`btn secondary outline ${hasValidationError(step) ? "error" : ""}`}
						>
							{currentDashboardData?.id ? "Update" : "Create"} Business Insights Dashboard
						</button>
					)}
				</>
			)}
		</div>,
	];
};

export const handleMonetaryChange = (value, index, handleOnDiscriptionChange, fieldName) => {
	const cleanValue = value.replace(/[$,]/g, "");

	const numericValue = cleanValue.replace(/[^0-9.]/g, "");

	const parts = numericValue.split(".");

	let finalValue = parts[0];
	if (parts.length > 1) {
		finalValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
	}

	if (finalValue === "" || isNaN(finalValue)) {
		handleOnDiscriptionChange(fieldName, "", index);
		return;
	}

	handleOnDiscriptionChange(fieldName, finalValue, index);
};

export const formatMonetaryValue = (value) => {
	if (!value || value === "") return "";

	const cleanValue = value.toString().replace(/[$,]/g, "");

	const numericValue = parseFloat(cleanValue);
	if (isNaN(numericValue)) return "";

	return numericValue.toFixed(2);
};
