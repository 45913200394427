import axios from "axios";

import { store } from "../redux/store";
import { ToastShow } from "../redux/toast.duck";
import { setToken } from "../redux/token/token.action";
import { setCurrentUser } from "../redux/user/user.action";

const DEFAULT_MS_TIMEOUT = 4 * 60 * 1000;
export const axiosInstance = axios.create({
	timeout: import.meta.env.VITE_REACT_APP_API_TIMEOUT || DEFAULT_MS_TIMEOUT,

	baseURL: import.meta.env.VITE_REACT_APP_API_URL,
	headers: {
		"accept": "application/json",
		"content-type": "application/json",
	},
});

const redirect = () =>
	setTimeout(() => {
		window.location = "/login";
	}, 10);

axiosInstance.interceptors.request.use(async (config) => {
	try {
		const state = store.getState();
		const token = state.token;

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	} catch (err) {
		Promise.reject(err);
	}
});

axiosInstance.interceptors.response.use(
	(res) => {
		if (res.data.toast === true) {
			if (res.data.code === 200) {
				store.dispatch(
					ToastShow({
						message: res.data.message,
						dataError: res.data.data,
						toggle: true,
						type: "success",
					}),
				);
			} else {
				store.dispatch(
					ToastShow({
						message: res.data.message,
						dataError: res.data.data,

						toggle: true,
						type: "error",
					}),
				);
			}
		}
		setTimeout(() => {
			store.dispatch(
				ToastShow({
					message: "",
					toggle: false,
					type: "",
				}),
			);
		}, 5000);
		return res.data;
	},
	(error) => {
		if (error.name === "AbortError" || error.name === "CanceledError") {
			return;
		}

		console.error("API ERROR", error);
		// The server can return either a standard HTTP error response or a custom response.
		// (See the Laravel app's GeneralError class for JSON resource error responses.)
		const statusCode = error.status ?? error.response?.status ?? null;
		const errorMessage =
			error.response?.data?.message ?? error.error?.title ?? "there was a was a problem with the server";

		switch (statusCode) {
			/**
			 * 401: UNAUTHORIZED / 403: FORBIDDEN
			 * Current user is not logged in, session has expired, or lacks permission.
			 * Redirect user to login.
			 */
			case 401:
			case 403:
				store.dispatch(setCurrentUser(null));
				store.dispatch(setToken(null));
				store.dispatch(
					ToastShow({
						message: `Authentication Error: (${statusCode}) ${errorMessage}`,
						toggle: true,
						type: "error",
					}),
				);
				redirect();
				break;

			case 404:
				store.dispatch(
					ToastShow({
						message: `Resource Not Found: ${errorMessage}`,
						toggle: true,
						type: "error",
					}),
				);
				redirect();
				break;

			/**
			 * CATCH ALL
			 * All other API errors handled here.
			 */
			default:
				store.dispatch(
					ToastShow({
						message: `Network Error: (${statusCode || "error"}) ${errorMessage}`,
						toggle: true,
						type: "error",
					}),
				);
				redirect();
				break;
		}
	},
);

// export default axios;

export const axiosPost = (url, data, config = null) => {
	return axiosInstance.post(`${url}`, data, config);
};

export const axiosDelete = (url) => {
	return axiosInstance.delete(`${url}`);
};

// `config` allows for passing in additional Axios configurations like `signal` for cancelling requests.
export const axiosGet = async (url, data = {}, config = {}) => {
	const localConfig = {
		params: data,
		...config,
	};
	try {
		return await axiosInstance.get(`${url}`, localConfig);
	} catch (error) {
		// Return the error response, if available, or the error object itself.
		return error.response || error;
	}
};

export const axiosUploadFormData = (url, formData = new FormData(), setProgressFileUpload) => {
	return axiosInstance.post(`${url}`, formData, {
		headers: { "Content-Type": "multipart/form-data" },
		onUploadProgress: (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			setProgressFileUpload && setProgressFileUpload(percentCompleted);
		},
	});
};
