import React from "react";
import { checkValueIsPositiveDecimal } from "../../../utils/constants";
import SelectWithSearchBox from "../../../pages/townson-dashboard-list/components/client-data-upload-component/select-with-search-box";
import InputText from "../../InputText/InputText";
import InputCheckbox from "../../InputCheckbox/InputCheckbox";
import InputSelect from "../../InputSelect/InputSelect";
import InputTextarea from "../../InputTextArea/InputTextArea";
import { handleMonetaryChange } from "../CreateCloneBusinessInsightsDashboard.helper";
import { addCommas } from "../../../helper/number.helpers";

function GeneralLocationFields({
	handleBlur,
	handleFocus,
	maxLengthTextArea,
	location,
	locationDescription,
	handleOnDiscriptionChange,
	index,
	handleOnAddressChange,
	hideLocation = false,
}) {
	const getFieldValue = (fieldName) => {
		if (!locationDescription[index]) return "";
		return locationDescription[index][fieldName] || "";
	};

	const statusOptions = [
		{ value: "Existing", label: "Existing" },
		{ value: "Under Construction", label: "Under Construction" },
		{ value: "Proposed", label: "Proposed" },
	];

	return (
		<>
			{!hideLocation && (
				<SelectWithSearchBox
					selectedProposedLocation={location.proposedLocations}
					setSelectedItem={(e) => {
						handleOnAddressChange(e, "proposedLocations", index);
					}}
					key={index}
					index={index}
					lableName='Location'
				/>
			)}
			<div className='is_current'>
				<InputCheckbox
					label='Is a Current Location?'
					name='is_current'
					isChecked={locationDescription[index]?.is_current || false}
					onValueChange={(checked) => {
						handleOnDiscriptionChange("is_current", checked, index);
					}}
				/>
			</div>
			<InputSelect
				id='status'
				label='Select Status'
				value={locationDescription[index]?.status || ""}
				onValueChange={(value) => {
					handleOnDiscriptionChange("status", value, index);
				}}
				options={[
					{ value: "Existing", label: "Existing" },
					{ value: "Under Construction", label: "Under Construction" },
					{ value: "Proposed", label: "Proposed" },
				]}
				theme='default'
				size='md'
				required={false}
				allowNull={true}
				nullOptionText='Choose Status'
				invalid={false}
				disabled={false}
			/>
			<InputText
				className={locationDescription[index]?.base_rent ? "hasFocus" : ""}
				type='text'
				name='base_rent'
				label='Base Rent'
				onFocus={() => handleFocus({ target: { name: "base_rent" } })}
				onBlur={() => {
					handleBlur({ target: { name: "base_rent" } });
					const currentValue = locationDescription[index]?.base_rent;
					if (currentValue) {
						const formattedValue = formatMonetaryValue(currentValue);
						handleOnDiscriptionChange("base_rent", formattedValue, index);
					}
				}}
				value={
					locationDescription[index]?.base_rent
						? `$${addCommas(String(locationDescription[index].base_rent).split(".")[0])}${
								String(locationDescription[index].base_rent).includes(".")
									? "." + String(locationDescription[index].base_rent).split(".")[1]
									: ""
						  }`
						: ""
				}
				onValueChange={(value) => handleMonetaryChange(value, index, handleOnDiscriptionChange, "base_rent")}
			/>
			<InputText
				className={locationDescription[index]?.opex_value ? "hasFocus" : ""}
				type='text'
				name='opex_value'
				label='Operating Expenses (Annually)'
				onFocus={() => handleFocus({ target: { name: "opex_value" } })}
				onBlur={() => handleBlur({ target: { name: "opex_value" } })}
				value={
					locationDescription[index]?.opex_value
						? `$${addCommas(String(locationDescription[index].opex_value).split(".")[0])}${
								String(locationDescription[index].opex_value).includes(".")
									? "." + String(locationDescription[index].opex_value).split(".")[1]
									: ""
						  }`
						: ""
				}
				onValueChange={(value) => handleMonetaryChange(value, index, handleOnDiscriptionChange, "opex_value")}
			/>
			<InputText
				className={locationDescription[index]?.gross_price_sf ? "hasFocus" : ""}
				type='text'
				name='gross_price_sf'
				label='Gross PSF'
				onFocus={() => handleFocus({ target: { name: "gross_price_sf" } })}
				onBlur={() => {
					handleBlur({ target: { name: "gross_price_sf" } });
					const currentValue = locationDescription[index]?.gross_price_sf;
					if (currentValue) {
						const formattedValue = formatMonetaryValue(currentValue);
						handleOnDiscriptionChange("gross_price_sf", formattedValue, index);
					}
				}}
				value={
					locationDescription[index]?.gross_price_sf
						? `$${addCommas(String(locationDescription[index].gross_price_sf).split(".")[0])}${
								String(locationDescription[index].gross_price_sf).includes(".")
									? "." + String(locationDescription[index].gross_price_sf).split(".")[1]
									: ""
						  }`
						: ""
				}
				onValueChange={(value) =>
					handleMonetaryChange(value, index, handleOnDiscriptionChange, "gross_price_sf")
				}
			/>
			<InputText
				className={locationDescription[index]?.leasing_company ? "hasFocus" : ""}
				type='text'
				name='leasing_company'
				label='Leasing Company Name'
				onFocus={() => handleFocus({ target: { name: "leasing_company" } })}
				onBlur={() => handleBlur({ target: { name: "leasing_company" } })}
				value={getFieldValue("leasing_company")}
				onValueChange={(value) => handleOnDiscriptionChange("leasing_company", value, index)}
			/>
			<InputTextarea
				name='notes'
				label='Notes'
				id={`notes-${index}`}
				value={getFieldValue("notes")}
				maxLength={maxLengthTextArea}
				onValueChange={(value) => {
					handleOnDiscriptionChange("notes", value, index);
				}}
			/>
		</>
	);
}

export default GeneralLocationFields;
