import "./upload-pdfs-to-locations.css";

import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function UploadPDFsToLocations({
	index,
	handleLocationPDFFiles,
	location,
	errorFileUpload,
	progressFileUpload,
	pdfFileName,
	removePDFFile,
	cancelPDFUpload,
}) {
	const currentUser = useSelector((state) => state.user);

	const existingFilesAmount = location?.pdfs?.length ?? 0;

	const dropZone = document.getElementsByClassName("upload-area-location-" + index);

	useEffect(() => {
		const FILE_SIZE_LIMIT_MB = import.meta.env.VITE_PDF_FILE_UPLOAD_SIZE_LIMIT;
		const TOTAL_FILE_LIMIT = import.meta.env.VITE_PDF_FILE_UPLOAD_LIMIT;

		const isFileSizeValid = (file) => file.size / 1024 / 1024 <= FILE_SIZE_LIMIT_MB;

		const handleDrop = (e) => {
			e.stopPropagation();
			e.preventDefault();

			const { files } = e.dataTransfer;
			const newFilesToAdd = Array.from(files);
			const currentFileCount = (pdfFileName[0]?.files?.length || 0) + existingFilesAmount;
			const totalAfterNewUpload = currentFileCount + newFilesToAdd.length;

			if (totalAfterNewUpload > TOTAL_FILE_LIMIT) {
				alert(`You have exceeded the limit of ${TOTAL_FILE_LIMIT} files total.`);
				return;
			}

			for (const file of newFilesToAdd) {
				if (!isFileSizeValid(file)) {
					alert(`File ${file.name} is too large, exceeds ${FILE_SIZE_LIMIT_MB}MB.`);
					return;
				}
			}

			handleLocationPDFFiles({
				location_index: index,
				files: newFilesToAdd,
			});
		};

		if (dropZone.length > 0) {
			dropZone[0].addEventListener("dragover", function (e) {
				e.stopPropagation();
				e.preventDefault();
				e.dataTransfer.dropEffect = "copy";
			});

			dropZone[0].addEventListener("drop", handleDrop);
		}

		return () => {
			if (dropZone.length > 0) {
				dropZone[0].removeEventListener("drop", handleDrop);
			}
		};
	}, [existingFilesAmount, pdfFileName, index]);

	return (
		<>
			{currentUser?.company_upload_pdfs_enabled ||
			currentUser?.roles?.some((role) => role.name === "Super Admin") ? (
				<>
					<p className='upload-label'>Upload Location Flyer (PDF)</p>
					<div className='tool-upload-wrapper'>
						<div className='inner'>
							<div className={`upload-area ${"upload-area-location-" + index}`}>
								<input
									type='file'
									accept='.pdf'
									name='file'
									id='file'
									onChange={(e) => {
										const FILE_SIZE_LIMIT_MB = import.meta.env.VITE_PDF_FILE_UPLOAD_SIZE_LIMIT;
										const TOTAL_FILE_LIMIT = import.meta.env.VITE_PDF_FILE_UPLOAD_LIMIT;

										const isFileSizeValid = (file) => file.size / 1024 / 1024 <= FILE_SIZE_LIMIT_MB;
										const currentFileCount =
											existingFilesAmount + (pdfFileName[0]?.files?.length || 0);
										const newFilesToAdd = Array.from(e.target.files);
										const totalAfterNewUpload = currentFileCount + newFilesToAdd.length;

										if (totalAfterNewUpload > TOTAL_FILE_LIMIT) {
											alert(`You have exceeded the limit of ${TOTAL_FILE_LIMIT} files total.`);
											return;
										}

										for (const file of newFilesToAdd) {
											if (!isFileSizeValid(file)) {
												alert(
													`File ${file.name} is too large, exceeds ${FILE_SIZE_LIMIT_MB}MB.`,
												);
												return;
											}
										}

										handleLocationPDFFiles({
											location_index: index,
											e,
										});
									}}
									onClick={(event) => {
										event.target.value = null;
									}}
									multiple
								/>

								<span>Drag and drop flyers (PDF) to upload or</span>
								<u>browse</u>
							</div>
							{pdfFileName[0]?.files?.length > 0 && (
								<div className='upload-progress-title-wrapper'>
									<div className='upload-progress-title'>
										{pdfFileName[0]?.files?.map((file, fileIndex) => {
											const progress = progressFileUpload[`${index}-${fileIndex}`] || 0;
											return (
												<div key={fileIndex} className='progress-container'>
													<div className='upload-icon-text'>
														<label>{file.name ?? file}</label>
													</div>
													<div className='upload-progress-bar'>
														<div
															className='progress-bar'
															style={{
																backgroundColor: "#5C86C1",
																width: `${progress}%`,
																height: "4px",
															}}
														></div>
														{progress === 100 && (
															<span style={{ color: "green" }}>Uploaded</span>
														)}
														{progress > 0 && progress < 100 && <span>{progress}%</span>}
													</div>
													{progress === 100 && (
														<div
															className='stop-progress'
															onClick={() => {
																cancelPDFUpload(file);
															}}
														>
															<svg
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M18 6L6 18'
																	stroke='#111128'
																	strokeWidth='2'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path
																	d='M6 6L18 18'
																	stroke='#111128'
																	strokeWidth='2'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
															</svg>
														</div>
													)}
												</div>
											);
										})}
									</div>
								</div>
							)}
							{errorFileUpload && (
								<div className='upload-progres-title-wrapper'>
									<span
										style={{
											color: "#F44336",
										}}
									>
										{`* ${errorFileUpload}`}
									</span>
								</div>
							)}
						</div>
					</div>
					<ul className='files-list'>
						{location?.pdfs?.length > 0 &&
							location?.pdfs?.map((loc, index) => {
								return (
									<li key={index}>
										<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='red'>
											<title>{loc.name}</title>
											<path d='M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z'></path>
										</svg>
										<p
											onClick={() => {
												removePDFFile({
													location_id: location._id,
													file: loc.name,
												});
											}}
										>
											Remove
										</p>
									</li>
								);
							})}
					</ul>
				</>
			) : (
				""
			)}
		</>
	);
}
