import React, { useState, useEffect } from "react";
import InputText from "../../components/InputText/InputText";
import { useComparison } from "./ComparisonContext";

const formatNumericValue = (value, suffix = "") => {
	if (!value && value !== 0) return "";

	if (String(value).includes("/") || String(value).includes("|")) {
		const separator = String(value).includes("/") ? "/" : "|";
		const parts = String(value).split(separator);

		const formattedParts = parts.map((part) => {
			const cleanPart = part.replace(/,/g, "");

			if (!isNaN(cleanPart) && !/[^0-9.]/.test(cleanPart)) {
				const num = Number(cleanPart);

				if (String(cleanPart).slice(-1) !== ".") {
					if (String(cleanPart).includes(".")) {
						return num.toLocaleString(undefined, {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						});
					}
					return num.toLocaleString();
				}
				return cleanPart;
			}
			return part;
		});

		return formattedParts.join(separator);
	}

	if (!isNaN(value) && !/[^0-9.]/.test(value)) {
		const num = Number(value);

		if (String(value).slice(-1) !== ".") {
			if (String(value).includes(".")) {
				return num.toLocaleString(undefined, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			}
			return num.toLocaleString();
		}
	}
	return value;
};

const EditableCell = ({ location, metricKey, currentValue, isEditable, commas = true, suffix = "" }) => {
	const { metricEdits, updateMetricValue } = useComparison();
	const [isEditing, setIsEditing] = useState(false);

	if (metricKey === "leasing_company" && isEditable) {
		const textValue = metricEdits[location._id]?.[metricKey] ?? (currentValue ? currentValue.toString() : "");

		return (
			<InputText
				value={textValue}
				onValueChange={(newValue) => {
					updateMetricValue(location._id, metricKey, newValue);
				}}
				onFocus={() => setIsEditing(true)}
				onBlur={() => setIsEditing(false)}
				size='sm'
				theme='gray-outline'
			/>
		);
	}

	if (!isEditable) {
		const displayWithSuffix = currentValue !== "--" && suffix ? `${currentValue} ${suffix}` : currentValue;
		return <div dangerouslySetInnerHTML={{ __html: displayWithSuffix }} />;
	}

	const rawValue =
		metricEdits[location._id]?.[metricKey] ?? (currentValue ? currentValue.toString().replace(/,/g, "") : "");

	let displayValue = isEditing ? rawValue : commas ? formatNumericValue(rawValue) : rawValue;

	if (!isEditing && suffix && displayValue && displayValue !== "--") {
		displayValue = `${displayValue} ${suffix}`;
	}

	return (
		<InputText
			value={displayValue}
			onValueChange={(newValue) => {
				const valueWithoutSuffix = newValue.replace(` ${suffix}`, "");

				const sanitizedValue = valueWithoutSuffix
					.replace(/[^\d./|]/g, "")
					.replace(/(\..*)\./g, "$1")
					.replace(/([/|].*)[/|]/g, "$1");

				updateMetricValue(location._id, metricKey, sanitizedValue);
			}}
			onFocus={() => setIsEditing(true)}
			onBlur={() => setIsEditing(false)}
			size='sm'
			theme='gray-outline'
		/>
	);
};

export default EditableCell;
