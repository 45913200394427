import React from "react";
import Accordion from "../../Accordion/Accordion";
import { checkValueIsPositiveDecimal } from "../../../utils/constants";
import { addCommas, formatMoney } from "../../../helper/number.helpers";
import InputText from "../../InputText/InputText";
import { handleMonetaryChange, formatMonetaryValue } from "../CreateCloneBusinessInsightsDashboard.helper";
function IndustrialFields({ handleBlur, handleFocus, index, locationDescription, handleOnDiscriptionChange }) {
	const getFieldValue = (fieldName) => {
		if (!locationDescription[index]) return "";

		switch (fieldName) {
			case "building_sf":
			case "available_sf":
			case "office_sf":
				return locationDescription[index][fieldName] ? addCommas(locationDescription[index][fieldName]) : "";
			default:
				return locationDescription[index][fieldName] || "";
		}
	};

	return (
		<Accordion collapsedTitle='Show all industrial fields' expandedTitle={"Hide all industrial fields"}>
			<InputText
				className={locationDescription[index]?.building_sf ? "hasFocus" : ""}
				type='text'
				name='building_sf'
				label='Building SF'
				onFocus={() => handleFocus({ target: { name: "building_sf" } })}
				onBlur={() => handleBlur({ target: { name: "building_sf" } })}
				value={getFieldValue("building_sf")}
				onValueChange={(value) =>
					handleOnDiscriptionChange("building_sf", checkValueIsPositiveDecimal(value), index)
				}
			/>

			<InputText
				className={locationDescription[index]?.available_sf ? "hasFocus" : ""}
				type='text'
				name='available_sf'
				label='Available SF'
				onFocus={() => handleFocus({ target: { name: "available_sf" } })}
				onBlur={() => handleBlur({ target: { name: "available_sf" } })}
				value={getFieldValue("available_sf")}
				onValueChange={(value) =>
					handleOnDiscriptionChange("available_sf", checkValueIsPositiveDecimal(value), index)
				}
			/>

			<InputText
				className={locationDescription[index]?.office_sf ? "hasFocus" : ""}
				type='text'
				name='office_sf'
				label='Office SF'
				onFocus={() => handleFocus({ target: { name: "office_sf" } })}
				onBlur={() => handleBlur({ target: { name: "office_sf" } })}
				value={getFieldValue("office_sf")}
				onValueChange={(value) =>
					handleOnDiscriptionChange("office_sf", checkValueIsPositiveDecimal(value), index)
				}
			/>

			<InputText
				className={locationDescription[index]?.clear_height ? "hasFocus" : ""}
				type='text'
				name='clear_height'
				label='Clear Height'
				onFocus={() => handleFocus({ target: { name: "clear_height" } })}
				onBlur={() => handleBlur({ target: { name: "clear_height" } })}
				value={getFieldValue("clear_height")}
				onValueChange={(value) =>
					handleOnDiscriptionChange("clear_height", checkValueIsPositiveDecimal(value), index)
				}
			/>

			<InputText
				className={locationDescription[index]?.car_trailer_parking ? "hasFocus" : ""}
				type='text'
				name='car_trailer_parking'
				label='Car/Trailer Parking'
				onFocus={() => handleFocus({ target: { name: "car_trailer_parking" } })}
				onBlur={() => handleBlur({ target: { name: "car_trailer_parking" } })}
				value={getFieldValue("car_trailer_parking")}
				onValueChange={(value) => handleOnDiscriptionChange("car_trailer_parking", value, index)}
			/>

			<InputText
				className={locationDescription[index]?.docs_grade_level_doors ? "hasFocus" : ""}
				type='text'
				name='docs_grade_level_doors'
				label='Docks/Grade Level Doors'
				onFocus={() => handleFocus({ target: { name: "docs_grade_level_doors" } })}
				onBlur={() => handleBlur({ target: { name: "docs_grade_level_doors" } })}
				value={getFieldValue("docs_grade_level_doors")}
				onValueChange={(value) => handleOnDiscriptionChange("docs_grade_level_doors", value, index)}
			/>
		</Accordion>
	);
}

export default IndustrialFields;
